<template>
  <div class="row">
    <div class="col-xs-12">
      <router-link to="/">Home</router-link>
    </div>
    <div class="col-xs-12 col-md-6">
      <Swipe3d />
    </div>
    <div class="col-xs-12 col-md-6">
      <div class="about">
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit.
          Enim, voluptatem deserunt optio minima omnis natus mollitia quas!
          Doloribus modi, esse rem, iure pariatur, quisquam blanditiis repellat
          dolores ex sit tempora.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Swipe3d from '@/components/Swipe3d.vue';

export default {
  name: 'About',
  components: {
    Swipe3d,
  },
};
</script>

<style lang="scss" scoped>
.about {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  p {
    font-size: 1.2em;
    margin: 0 2em;
  }
}
</style>
