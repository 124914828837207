<template>
  <div class="c-SwipeGallery">
    <div
      v-if="!isLoading"
      class="c-SwipeGallery__handler"
      v-touch:start="startHandler"
      v-touch:end="endHandler"
      v-touch:moving="movingHandler"
    >
      <div class="c-SwipeGallery__itemWrapper">
        <img
          :src="`/img/products/product1/GN274_${galleryIndex}.png`"
          alt=""
          draggable="false"
        >
      </div>
    </div>
    <div
      v-else
      class="c-SwipeGallery__loader"
    >
      <img
        src="/img/products/product1/GN274_1.png"
        alt="Preload image"
        class="c-SwipeGallery__preview"
        draggable="false"
      >

      <img
        :src="`/img/products/product1/GN274_${galleryIndex}.png`"
        alt="Preload image"
        @load="loadNextImage"
        class="c-SwipeGallery__preload"
        draggable="false"
      >
    </div>
  </div>
</template>

<script>
import debounce from '@/utilities';

let rotateInterval = null;

export default {
  name: 'Swipe3d',
  data: () => ({
    awaitPreload: false,
    isLoading: true,
    galleryIndex: 1,
    isDragging: false,
    currentX: 0,
    rotateInterval: null,
  }),
  watch: {
    isLoading(loading) {
      if (!loading) {
        this.animateRotation();
      }
    },
  },
  mounted() {
    // this.preloadImages();
  },
  methods: {
    preloadImages() {
      if (!this.awaitPreload) {
        this.awaitPreload = true;
        this.galleryIndex += 1;
      }
    },
    loadNextImage() {
      if (this.galleryIndex < 59) {
        this.awaitPreload = false;
        this.preloadImages();
      } else {
        this.galleryIndex = 1;
        this.isLoading = false;
      }
    },
    startHandler(e) {
      clearInterval(rotateInterval);
      this.currentX = e.touches?.[0].clientX || e.x;
      this.isDragging = true;
    },
    endHandler() {
      this.currentX = 0;
      this.isDragging = false;
      setTimeout(() => {
        this.animateRotation();
      }, 500);
    },
    // eslint-disable-next-line
    movingHandler: debounce(function (e) {
      if (this.isDragging) {
        const x = e.touches?.[0].clientX || e.x;
        if (this.currentX < x) {
          // this.currentX = x;
          if (this.galleryIndex < 59) {
            this.galleryIndex += 1;
          } else {
            this.galleryIndex = 1;
          }
        }
        if (this.currentX > x) {
          if (this.galleryIndex > 1) {
            this.galleryIndex -= 1;
          } else {
            this.galleryIndex = 59;
          }
        }
        this.currentX = x;
      }
    }, 5),
    animateRotation() {
      rotateInterval = setInterval(() => {
        if (this.galleryIndex < 59) {
          this.galleryIndex += 1;
        } else {
          this.galleryIndex = 1;
        }
      }, 90);
    },
  },
};
</script>

<style lang="scss" scoped>
.c-SwipeGallery {
  &__handler {
    position: relative;
  }
  &__itemsWrapper {
    display: flex;
    width: 100%;
  }
  &__itemWrapper {
    display: flex;
    align-items: center;
    cursor: grab;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__loader {
    display: flex;
    margin: 0 auto;
  }

  &__preview {
    height: 100%;
    width: 100%;
    min-height: 450px;
  }

  &__preload {
    height: 1px;
    width: 1px;
  }
}
</style>
